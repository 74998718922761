import React, { useContext, useEffect, useCallback, useState, useRef } from 'react';
import { AuthContext } from '../components/AuthContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Box, Tooltip } from '@mui/material';
import { API_DOMAIN, API_ENDPOINTS, capitalizeFirstLetterOfEachWord } from '../components/constants';
import Modal from '@mui/material/Modal';
import useSnackbar from '../components/Snackbar/UseSnackbar';
import { apiCall } from '../components/Api/Api';
import { ReactTags } from 'react-tag-autocomplete'
import Checkbox from '@mui/material/Checkbox';
import LoadingComponent from '../components/LoadingComponent/Loading';

function Dashboard() {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '2px solid lightBlue',
        borderRadius: 6,
        boxShadow: 24,
        p: 4,
        width: "900px",
        height: "450px",
        display: "flex",
        alignItems: "left",
        flexDirection: "column",
    };
    const style_sup_add = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '2px solid lightBlue',
        borderRadius: 6,
        boxShadow: 24,
        p: 4,
        width: "80vw",
        height: "500px",
        display: "flex",
        alignItems: "left",
        flexDirection: "column",
    };
    const style_Tag = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "35%",
        bgcolor: 'background.paper',
        border: '2px solid lightBlue',
        borderRadius: 6,
        boxShadow: 24,
        p: 4,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
    };

    const { SuccessSnackbar, ErrorSnackbar, InfoSnackbar, WarningSnackbar, ToastContainer } = useSnackbar();

    const { authState } = useContext(AuthContext);

    let id = authState.id;

    var wa_status = "";
    const [supplierSet, setsupplierSet] = useState([]);
    const [supplierSetMap, setSupplierSetMap] = useState({});

    const [tagSet, setTagSet] = useState([]);
    const [tagData, setTagData] = useState("");
    const [tagSuggestions, setTagSuggestions] = useState([]);
    const [checked, setChecked] = useState(false);

    const [searchTerm, setSearchTerm] = useState("");
    const [supStatus, setSupStatus] = useState("1");
    const tableRef = useRef(null);

    //supplier formdata
    const [SupplierTags, setSupplierTags] = useState([]);
    const [SupplierMob, setSupplierMob] = useState("");
    const [SupplierName, setSupplierName] = useState("");
    const [SupplierAddress, setSupplierAddress] = useState("");


    const [isLoading, setisLoading] = useState(true);
    const [supplierId, setSupplierId] = useState("");


    const [formValid, setformValid] = useState(false);

    const [open_add_modal, setAddModalState] = useState(false);

    const handleOpenAddModal = () => {
        setAddModalState(true);
    };
    const handleCloseAddModal = () => {
        setAddModalState(false);
    };
    const scrollToBottom = () => {
        if (tableRef.current) {
            tableRef.current.scrollIntoView({ behavior: "smooth", block: "end" })
        }
    };

    const handleEdit = (id) => {
        setSupplierId(id);
        onSupMobChange(supplierSetMap[id].number, id);
        setTimeout(() => {
            document.getElementById('supAddModalTitle').innerHTML = (`Edit supplier '${supplierSetMap[id].name}' (${id})`);
        }, 0);
        setSupplierName(supplierSetMap[id].name);
        setSupplierMob(supplierSetMap[id].number);
        setSupplierAddress(supplierSetMap[id].address);
        setSupplierTags(supplierSetMap[id].tag);
        handleSupAddOpen();
    };
    const deleteTag = async (id) => {
        try {
            const response = await apiCall({
                api_key: "uzdpart",
                id: id
            }, API_ENDPOINTS.delete_supplier_tags, "post");
            if (response.success) {
                setTagSet(response.data);
                SuccessSnackbar("Deleted Tag Successfully");
            }
            else {
                ErrorSnackbar(response.message);
            }
        } catch (error) {
            ErrorSnackbar(error.message);
        }
    };
    const deleteSupplier = async (id, deleteSup) => {
        try {
            const response = await apiCall({
                api_key: "uzdpart",
                id: id,
                deleteSup,
                supStatus
            }, API_ENDPOINTS.disable_suppliersV2, "post");
            if (response.success) {
                setsupplierSet(response.data);
                SuccessSnackbar(response.message);
            }
            else {
                ErrorSnackbar(response.message);
            }
        } catch (error) {
            ErrorSnackbar(error.message);
        }
    };
    const handleTagDelete = async (tag) => {
        if (tag.tagged_supplier_count > 0) {
            const message = `${tag.tagged_supplier_count} Suppliers are tagged with '${tag.name}', Are you sure you want to delete? `;
            if (window.confirm(message)) {
                deleteTag(tag.id);
                handleTagManagement();
            }
        }
        else {
            deleteTag(tag.id);
            handleTagManagement();
        }
    };
    const handleSupplierDelete = async (supplier, deleteSup) => {
        const message = deleteSup ? `Are you sure to delete supplier '${supplier.name}' ? ` : `Are you sure to Re-Activate supplier '${supplier.name}' ? `;
        if (window.confirm(message)) {
            deleteSupplier(supplier.id, deleteSup);
        }
    };
    const [open_edit, setModalEdit] = useState(false);
    const handleOpenEdit = () => {
        setModalEdit(true);
    };
    const handleCloseEdit = () => {
        setModalEdit(false);
    };
    const [open_sup_add, setSupModal] = useState(false);
    const handleSupAddOpen = () => {
        setSupModal(true);
    };
    const handleSupAddClose = () => {
        setSupModal(false);
        clearSupplierForm();

    };
    const handleModalAdd = async () => {
        try {
            const response = await apiCall({
                api_key: "uzdpart",
                tag: tagData
            }, API_ENDPOINTS.add_supplier_tags, "post");
            if (response.success) {
                SuccessSnackbar("Added new tag!");
                handleCloseAddModal();
                setTagData("");
                setTagSet(response.data);
                setTimeout(() => {
                    scrollToBottom();
                }, 0);
            }
            else {
                ErrorSnackbar(response.message);
            }
        } catch (error) {
            ErrorSnackbar(error.message);
        }
    };
    const resetInput = (id) => {
        switch (id) {
            case 'search_supplier':
                setSearchTerm("");
                reload_supplier_data("", supStatus)
                break;
        }
    };
    const reload_supplier_data = async (value = "", supStatus) => {
        try {
            setisLoading(true);
            const response = await apiCall({
                api_key: "uzdpart",
                search: value,
                supStatus
            }, API_ENDPOINTS.get_suppliersV2, "post");
            if (response.success) {
                setisLoading(false);
                setsupplierSet(response.data);
                var dataset = {};
                response.data.forEach(dataValue => {
                    dataset[dataValue.id] = dataValue;
                });
                setSupplierSetMap(dataset);
                SuccessSnackbar("Fetched " + response.data.length + " Search result");
            }
            else {
                setisLoading(false);
                ErrorSnackbar(response.message);
            }
        } catch (error) {
            setisLoading(false);
            ErrorSnackbar(error.message);
        }
    };
    const reload_supplier_tags = async () => {
        axios.post(API_DOMAIN + "/whatsapp/get_supplier_tags", { api_key: "uzdpart" }).then((res) => {
            if (res.data.success) {
                setisLoading(false);
                setTagSet(res.data.data);
                var tagSetTemp = res.data.data.map((item, index) => ({
                    value: item.id,
                    label: item.name
                }));
                setTagSuggestions(tagSetTemp);
            }
        }).catch((error) => {
            setisLoading(false);
            ErrorSnackbar(error.message);
        });
    };
    const handleKeyPress = (e, id) => {
        const value = e.target.value;
        setSearchTerm(value)
        if (e.key === 'Enter') {
            switch (id) {
                case 'search_supplier':
                    reload_supplier_data(value, supStatus);
                    break;
            }
        }
    };
    const handleTagManagement = async () => {
        try {
            const response = await apiCall({
                api_key: "uzdpart"
            }, API_ENDPOINTS.get_supplier_tags, "post");
            if (response.success) {
                setTagSet(response.data);
                SuccessSnackbar("Fetched " + response.data.length + " Tags");
            }
            else {
                ErrorSnackbar(response.message);
            }
        } catch (error) {
            ErrorSnackbar(error.message);
        }
        handleOpenEdit();
    };
    const onSupMobChange = async (value, id) => {
        var flag = 0;
        setSupplierMob(value);
        setTimeout(async () => {
            try {
                var number = value.replace(/[^0-9]/g, '');
                if (number.length >= 10) {
                    flag = 1;
                }
                else if (number.startsWith('971') && number.length == 12) {
                    flag = 1;
                }
                else {
                    wa_status = "-";
                    document.getElementById("wa_valid").style = "color:black;text-align:right;padding-right:10px;";
                }
                if (flag == 1) {
                    const response = await apiCall({ api_secret: "uzdpart", phone: number, id }, API_ENDPOINTS.get_wa_status, "post");
                    if (!response.success && response.data.length > 0) {
                        wa_status = "Supplier Already Exists (" + response.data[0].name + ")";
                        setformValid(false);
                        document.getElementById("wa_valid").style = "color:red;";
                    }
                    if (response.success && response.data.status == 200) {
                        wa_status = "YES";
                        setformValid(true);
                        document.getElementById("wa_valid").style = "color:green;text-align:right;padding-right:10px;";
                    }
                    if (response.success && response.data.status == 404) {
                        wa_status = "NO";
                        setformValid(false);
                        document.getElementById("wa_valid").style = "color:red;text-align:right;padding-right:10px;";
                    }
                }
                document.getElementById("wa_valid").innerHTML = wa_status;
            } catch (error) {
                ErrorSnackbar(error.message);
            }
        }, 0);
    };
    const handleSupAdd = () => {
        if (formValid && SupplierTags.length > 0 && SupplierMob != "" && SupplierName != "") {

            axios.post(API_DOMAIN + "/whatsapp/add_suppliersV2", {
                api_key: "uzdpart", name: SupplierName, number: SupplierMob, address: SupplierAddress, tag: SupplierTags, id: supplierId, searchTerm
                , supStatus
            }).then((res) => {
                if (res.data.success) {
                    setsupplierSet(res.data.data);
                    SuccessSnackbar(res.data.message);
                    handleSupAddClose();
                }
                else {
                    ErrorSnackbar(res.data.message);
                }
            });
        }
        else {
            alert("Please enter mandatory values and a valid phone number.")
        }
    };
    const clearSupplierForm = () => {
        setSupplierId("");
        setSupplierName("");
        setSupplierMob("");
        setSupplierAddress("");
        setSupplierTags([]);
        document.getElementById("supAddModalTitle").innerHTML = "Add new Supplier";
    };
    const onAdd = useCallback(
        (newTag) => {
            setSupplierTags([...SupplierTags, newTag])
        },
        [SupplierTags]
    );
    const onDelete = useCallback(
        (tagIndex) => {
            setSupplierTags(SupplierTags.filter((_, i) => i !== tagIndex))
        },
        [SupplierTags]
    );
    const handleTagToggle = (event) => {
        const isChecked = event.target.checked;
        setChecked(isChecked);
        if (!checked) {
            setSupplierTags(tagSuggestions);
        }
        else {
            setSupplierTags([]);
        }
    };
    useEffect(() => {
        reload_supplier_data();
        reload_supplier_tags();
    }, []);
    const handleSupStatusChange = (e) => {
        setSupStatus(e.target.value);
        reload_supplier_data(searchTerm, e.target.value);
    };

    return (
        <div>
            <div style={{ display: 'flex' }}>
                <div style={{ marginLeft: "auto" }}>
                    <button style={{ width: "120px", backgroundColor: "rgb(210,200,110)", cursor: "pointer" }} onClick={(e) => handleSupAddOpen()}>Add Supplier</button>
                    <button style={{ width: "120px", backgroundColor: "rgb(110,200,110)", cursor: "pointer" }} onClick={(e) => handleTagManagement()}>Manage Tags</button>
                </div>
            </div>
            <div className="outer-container">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                        <Tooltip title="Type in the search term and press enter for results, clear the value using the x button to reset the search.">
                            <input value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} id='search_supplier' style={{ maxWidth: "100%", marginTop: "2px", margin: "2px", borderRadius: "5px" }} onKeyPress={(e) => handleKeyPress(e, 'search_supplier')} placeholder='Search Suppliers'>
                            </input>
                            <span style={{ position: "absolute", transform: "translateY(50px)", transform: "translateX(-20px)", cursor: "pointer", fontSize: "13px", color: "gray", marginTop: "4px", }} onClick={() => resetInput('search_supplier')}>&#10005;</span>
                        </Tooltip>
                        <select value={supStatus} onChange={handleSupStatusChange} style={{ marginLeft: "0px" }}>
                            <option value="all">All</option>
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                        </select>
                    </div>
                    <div style={{ fontSize: "22px" }}>SUPPLIERS</div>
                    <div style={{ fontSize: "22px" }}>Total Suppliers : {supplierSet.length}</div>
                </div>
                <div className="table-container">
                    <table className="supplier-table">
                        <thead>
                            <tr>
                                <th style={{ width: "35px" }}>SlNo</th>
                                <th>Supplier Name</th>
                                <th>Address</th>
                                <th>Mobile</th>
                                <th>Tags</th>
                                <th style={{ width: "60px" }}>Status</th>
                                <th style={{ width: "120px" }}>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {supplierSet.map((supplier, index) => (
                                <tr key={supplier.id}>
                                    <td>{index + 1}</td>
                                    <td>{supplier.name}</td>
                                    <td>{supplier.address}</td>
                                    <td>{supplier.number}</td>
                                    <td>
                                        <div>
                                            <span>Total :&nbsp;{supplier.tag != "" ? supplier.tag.length : 0}</span>
                                            <div style={{ overflowY: "auto", height: "80px", border: "1px black solid", borderRadius: "5px", padding: "2px 0px 0px 2px", backgroundColor: "papayawhip" }}>
                                                {supplier.tag != "" ? supplier.tag.map((element, index) => (
                                                    <span key={index}>
                                                        {index + 1}.{element.label}
                                                        <br />
                                                    </span>)) : <span>-</span>}
                                            </div>
                                        </div>
                                    </td>
                                    <td >{supplier.status === 1 ? 'Active' : 'Inactive'}</td>
                                    <td>
                                        <button onClick={() => handleEdit(supplier.id)} style={{ width: "30%" }}>✏️</button>
                                        {supplier.status === 1 ? <button onClick={() => handleSupplierDelete(supplier, true)} style={{ width: "30%" }}>🗑️</button> : <button onClick={() => handleSupplierDelete(supplier, false)} style={{ width: "30%" }}>↩️</button>}

                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {/* modal to see tags */}
                <Modal
                    open={open_edit}
                    onClose={handleCloseEdit}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    BackdropProps={{ onClick: (event) => event.stopPropagation() }}
                    disableEscapeKeyDown >
                    <Box sx={style}>
                        <h2 style={{ textAlign: "center", marginTop: "0px" }}>Manage Supplier Tags</h2>
                        //here search box
                        <div style={{ height: "290px", overflowY: "auto", border: "1px black solid", borderRadius: "5px" }}>
                            <table ref={tableRef} className="supplier-table">
                                <thead>
                                    <th style={{ width: "40px" }}>SlNo</th>
                                    <th style={{ width: "140px" }}>Tag Name</th>
                                    <th style={{ width: "160px" }}>No of Tagged Suppliers</th>
                                    <th>Suppliers</th>
                                    <th style={{ width: "60px" }}>Actions</th>
                                </thead>
                                <tbody>
                                    {tagSet.map((tag, index) => (
                                        <tr key={tag.id}>
                                            <td>{index + 1}</td>
                                            <td>{tag.name}</td>
                                            <td>{tag.tagged_supplier_count}</td>
                                            <td>
                                                <div style={{ overflowY: "auto", height: "70px" }}>
                                                    {tag.tagged_supplier_count > 0 ? tag.tagged_suppliers.map((element, index) => (
                                                        <span key={index}>
                                                            {index + 1}.{element}
                                                            <br />
                                                        </span>))
                                                        : <span>-</span>}
                                                </div>
                                            </td>
                                            <td>
                                                <button onClick={() => window.confirm("Do you want to delete '" + tag.name + "'?") ? handleTagDelete(tag) : ""} style={{ width: "80%", backgroundColor: "#1495b9" }}>🗑️</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <button id='modal_close_button' className='buttonito' style={{ width: "170px", backgroundColor: "red" }} onClick={handleCloseEdit}>Close</button>
                            <button id='modal_send_button' className='buttonito' style={{ width: "170px", backgroundColor: "green" }} onClick={handleOpenAddModal}>Add New</button>
                        </div>
                    </Box>
                </Modal>
            </div >
            {/* modal to add/edit new supplier */}
            <Modal
                open={open_sup_add}
                onClose={handleSupAddClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                BackdropProps={{ onClick: (event) => event.stopPropagation() }}
                disableEscapeKeyDown >
                <Box sx={style_sup_add}>
                    <h2 id="supAddModalTitle" style={{ textAlign: "center", marginTop: "0px" }}>Add new Supplier</h2>
                    <div style={{ height: "480px", border: "1px black solid", borderRadius: "5px", display: "flex" }}>
                        <table className='sup_add_table' style={{ margin: "5px", border: "1px black dashed", borderRadius: "5px", padding: "0px 10px 0px 10px" }}>
                            <tr>
                                <td>Supplier Whatsapp Number<span className="required">*</span></td>
                            </tr>
                            <tr>
                                <Tooltip title="Remeber to put the country code without plus(+) in the beginning like 971xxxxxxxx.">
                                    <td><input id="supNumber" type="number" value={SupplierMob} onChange={(e) => onSupMobChange(e.target.value)} required></input></td>
                                </Tooltip>
                            </tr>
                            <tr>
                                <td>
                                    Is valid WhatsApp Number?
                                    <div id="wa_valid" style={{ textAlign: "right", paddingRight: "10px" }}>-</div>
                                </td>
                            </tr>
                            <tr>
                                <td>Supplier Name<span className="required">*</span></td>
                            </tr>
                            <tr>
                                <td><input type="text" value={SupplierName} onChange={(e) => setSupplierName(e.target.value)} required></input></td>
                            </tr>
                            <tr>
                                <td><br /></td>
                            </tr>
                            <tr>
                                <td>Supplier Address</td>
                            </tr>
                            <tr>
                                <td><textarea value={SupplierAddress} style={{ width: "170px", height: "60px", resize: "none" }} onChange={(e) => setSupplierAddress(e.target.value)}></textarea></td>
                            </tr>
                        </table>
                        <div style={{ overflowY: "auto", height: "400px", margin: "15px 10px 0px 10px", width: "100%" }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <label for="ReactTags">Supplier Tags<span className="required">*</span></label>
                                <Tooltip title="Click here to toggle between selecting all tags or none.">
                                    <Checkbox
                                        checked={checked}
                                        onChange={handleTagToggle}
                                        sx={{ cursor: "pointer" }}
                                    />
                                </Tooltip>
                            </div>
                            <ReactTags
                                selected={SupplierTags}
                                suggestions={tagSuggestions}
                                placeholderText="Add Supplier Tags"
                                onAdd={onAdd}
                                onDelete={onDelete}
                                noOptionsText="No matches found"
                            />
                        </div>
                    </div>

                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <button id='modal_close_button' className='buttonito' style={{ width: "170px", backgroundColor: "red" }} onClick={handleSupAddClose}>Close</button>
                        <button id='modal_send_button' className='buttonito' style={{ width: "170px", backgroundColor: "green" }} onClick={handleSupAdd}>Save</button>
                    </div>
                </Box>
            </Modal>

            {/*Modal to showup to add tags */}
            <Modal
                open={open_add_modal}
                onClose={handleCloseAddModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style_Tag}>
                    <h2 style={{ textAlign: "center" }}>Add New Tag</h2>
                    <div>
                        <input id='region_value' value={tagData} onChange={(e) => { setTagData(capitalizeFirstLetterOfEachWord(e.target.value)) }} style={{ width: "300px" }} className='query_fields'></input>
                    </div>
                    <div>&nbsp;</div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <button id='wastat_modal_close_button' className='buttonito' style={{ width: "170px", backgroundColor: "red" }} onClick={(e) => handleCloseAddModal()}>Close</button>
                        <button id='wastat_modal_close_button' className='buttonito' style={{ width: "170px", backgroundColor: "green" }} onClick={handleModalAdd}>Save</button>
                    </div>
                </Box>
            </Modal>

            <ToastContainer />
            <LoadingComponent isLoading={isLoading} />
        </div >

    );

}

export default Dashboard
