const API_DOMAIN = "https://srm.uzdpart.com:33123"

// This is for testing purposes
// const API_DOMAIN = "https://srm.uzdpart.com:7550"

const eventStatusMap = {
    'qr': 'qr',
    'loading_screen': 'loading_screen',
    'init_whatsapp': 'init_whatsapp',
    'session_restoring': 'session_restoring',
    'logging_in': 'logging_in',
    'qr_timeout': 'qr_timeout',
    'logged_in': 'logged_in',
    'logged_out': 'logged_out',
};

const API_ENDPOINTS = {
    status: 'whatsapp/status',
    init_qr: 'whatsapp/init_qr',
    logout: 'whatsapp/logout',
    device_stats: 'whatsapp/device_stats',
    get_customers: 'whatsapp/get_customers',
    get_suppliers: 'whatsapp/get_suppliers',
    get_querysets: 'whatsapp/get_querysets',
    get_prevdata_make: 'whatsapp/get_prevdata_make',
    get_makes: 'whatsapp/get_makes',
    get_models: 'whatsapp/get_models',
    add_model_v2: 'whatsapp/add_model_v2',
    add_makes_v2: 'whatsapp/add_makes_v2',
    get_makes_v2: 'whatsapp/get_makes_v2',
    get_models_v2: 'whatsapp/get_models_v2',
    get_querysets_wati: 'whatsapp/get_querysets_wati',
    get_supplier_tags: 'whatsapp/get_supplier_tags',
    add_supplier_tags: 'whatsapp/add_supplier_tags',
    delete_supplier_tags: 'whatsapp/delete_supplier_tags',
    get_suppliersV2: 'whatsapp/get_suppliersV2',
    add_suppliersV2: 'whatsapp/add_suppliersV2',
    add_suppliersV2: 'whatsapp/add_suppliersV2',
    add_queryset: 'whatsapp/add_queryset',
    add_querysetV2: 'whatsapp/add_querysetV2',
    exec_wa_one: 'whatsapp/exec_wa_one',
    update_queryset: 'whatsapp/update_queryset',
    customer_add: 'whatsapp/customer_add',
    region_add_update: 'whatsapp/region_add_update',
    get_region: 'whatsapp/get_region',
    delete_region: 'whatsapp/delete_region',
    delete_messages: 'whatsapp/delete_messages',
    disable_suppliersV2: 'whatsapp/disable_suppliersV2',
    get_wa_status: 'whatsapp/get_wa_status',

    //auth
    user_register: 'auth/register',
    user_login: 'auth/login',
    user_fetch_by_id: 'auth/fetchy/:id',
    user_exist_check: 'auth/exists/:id',
    get_all_users: 'auth/get_all_users',
    update_user: 'auth/update_user',
}

const capitalizeFirstLetterOfEachWord = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
};

module.exports = { API_DOMAIN, eventStatusMap, API_ENDPOINTS, capitalizeFirstLetterOfEachWord }