import React, { useState, useContext, useEffect } from 'react';
import { Box, CssBaseline, Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography, Badge, Menu, MenuItem, Tooltip } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HomeIcon from '@mui/icons-material/Home';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import KeyIcon from '@mui/icons-material/Key';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { styled, useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { AuthContext } from '../components/AuthContext';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import useLogout from './Login/Logout';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import green_tick from '../assets/images/green_tick.png';
import yellow_error from '../assets/images/yellow_error.png';
import green_loader from '../assets/images/loader-green.gif';
import { API_DOMAIN, eventStatusMap } from './constants';
import axios from 'axios';
import useMediaQuery from '@mui/material/useMediaQuery';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': {
                ...openedMixin(theme),
                // backgroundColor: '#8c8c8c', // Custom background color
                // color: '#fff', // Custom text color
            },
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': {
                ...closedMixin(theme),
                // backgroundColor: '#8c8c8c', // Custom background color
                // color: '#fff', // Custom text color
            },
        }),
    }),
);

function stringAvatar(name) {
    if (name.split(' ').length === 2) {
        // If name has two parts (first name and last name)
        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    } else {
        // If name has only one part
        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: `${name.split(' ')[0][0].toUpperCase()}`, // Capitalize first letter
        };
    }
}

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

export default function Drawerr({ children, title }) {
    const { authState } = useContext(AuthContext);

    // const ws = new WebSocket(`wss://live-mt-server.wati.io/5068/chat?access_token=${authState.wati_token}`);

    // ws.NewChatAssigned = (event) => {
    //     console.log('Message received:', event.data);
    //     // Handle your message here
    // };

    // ws.onopen = () => {
    //     console.log('Connected to WebSocket');
    // };

    useEffect(() => {
        const waStatusCheck = async () => {
            document.getElementById('wa_status_img_small').src = green_loader;
            axios.post(API_DOMAIN + "/whatsapp/status", { api_secret: "uzdpart" }).then((res) => {
                if (res.data.success) {
                    // setWhatsappStatus(res.data.data.status);
                    switch (res.data.data.status.status) {
                        case eventStatusMap.logged_in:
                            document.getElementById('wa_status_img_small').src = green_tick
                            break;
                        default:
                            document.getElementById('wa_status_img_small').src = yellow_error
                            setTimeout(() => { waStatusCheck(); }, 1500);
                            break;
                    }
                } else {
                    document.getElementById('wa_status_img_small').src = yellow_error
                    setTimeout(() => { waStatusCheck(); }, 1500);
                }
            })
        }
        waStatusCheck();
    })
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [notificationsCount, setNotificationsCount] = useState(3);
    // console.log("title = ", authState);


    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
    const openMenu = Boolean(menuAnchorEl);
    const handleMenuClick = (event) => {
        setMenuAnchorEl(event.currentTarget);
    };
    const handleMenuClose = (e, mode) => {
        if (mode === 'Logout')
            handledialogueOpen();
        setMenuAnchorEl(null);
    };


    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleNotificationClick = (event) => {
        setAnchorEl(event.currentTarget);
        setNotificationsCount(0); // Reset notifications count when menu is opened
    };

    const handleNotificationClose = () => {
        setAnchorEl(null);
    };

    const { handledialogueOpen, LogoutDialog } = useLogout();

    const notifications = [
        "Notification 1",
        "Notification 2",
        "Notification 3",
    ];
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const calculatedMarginLeft = isSmallScreen ? `-50px` : `-310px`;
    const [marginLeft, setMarginLeft] = useState(0);
    useEffect(() => {
        const updateMargin = () => {
            const screenWidth = window.innerWidth;

            const drawerWidth = 310; // adjust this as per your drawer width
            const dynamicMargin = screenWidth >= 1920 ? -drawerWidth : -drawerWidth * (screenWidth / 3500);
            setMarginLeft(dynamicMargin);
        };
        // Initial calculation
        updateMargin();

        // Recalculate on window resize
        window.addEventListener('resize', updateMargin);

        return () => {
            window.removeEventListener('resize', updateMargin);
        };
    }, []);

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && { display: 'none' }),
                        }}
                        className="drawer-button"
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                        {title}
                    </Typography>



                    <Box>
                        <WhatsAppIcon color='green' sx={{ fontSize: "27px" }} />
                        <Tooltip title="WhatsApp bot status.">
                            <img id="wa_status_img_small" className='wa_stat_img' src={green_loader} alt='green_tick'></img>
                        </Tooltip>
                    </Box>


                    <IconButton
                        color="inherit"
                        aria-label="show notifications"
                        onClick={handleNotificationClick}
                        className='drawer-button'
                        sx={{ marginRight: "8px", marginLeft: "-13px" }}
                    >
                        <Badge badgeContent={notificationsCount} color="secondary">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleNotificationClose}
                    >
                        {notifications.length > 0 ? (
                            notifications.map((notification, index) => (
                                <MenuItem key={index} onClick={handleNotificationClose}>
                                    {notification}
                                </MenuItem>
                            ))
                        ) : (
                            <MenuItem onClick={handleNotificationClose}>No notifications</MenuItem>
                        )}
                    </Menu>
                    <div>
                        {!authState.status ? "" :
                            <Tooltip title={"Logged in as " + authState.username}>
                                <Stack direction="row" spacing={2}>
                                    <Avatar {...stringAvatar(authState.username)} onClick={handleMenuClick} />
                                </Stack>
                            </Tooltip>
                        }
                        <Menu
                            id="basic-menu"
                            anchorEl={menuAnchorEl}
                            open={openMenu}
                            onClose={handleMenuClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
                            <MenuItem onClick={handleMenuClose}>My account</MenuItem>
                            <MenuItem onClick={(e) => handleMenuClose(e, 'Logout')}>Logout</MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>

            {!authState.status ? (
                <Drawer variant="permanent" open={open}>
                    <DrawerHeader>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction == 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </DrawerHeader>
                    <Divider />
                    <List>
                        <ListItem key='Login' disablePadding sx={{ display: 'block' }}>
                            <ListItemButton
                                component={Link}
                                to={'/'}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <KeyIcon sx={{ color: '#4287f5' }} />
                                </ListItemIcon>
                                <ListItemText primary='Login' sx={{ opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                    <Divider />
                </Drawer>
            ) : (
                <Drawer variant="permanent" open={open}>
                    <DrawerHeader>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction == 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </DrawerHeader>
                    <Divider />
                    <List>
                        <ListItem key='Home' disablePadding sx={{ display: 'block' }}>
                            <ListItemButton
                                component={Link}
                                to={'/'}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <HomeIcon sx={{ color: title == 'Home' ? '#4287f5' : 'unset' }} />
                                </ListItemIcon>
                                <ListItemText primary='Home' sx={{ opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </ListItem>

                        <Divider />

                        {/* <ListItem key='Queryset' disablePadding sx={{ display: 'block' }}>
                            <ListItemButton
                                component={Link}
                                to={'/queryset'}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <WhatsAppIcon sx={{ color: title == 'Queryset' ? '#4287f5' : 'unset' }} />
                                </ListItemIcon>
                                <ListItemText primary='Queryset' sx={{ opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </ListItem> */}


                        <ListItem key='Queryset_v2' disablePadding sx={{ display: 'block' }}>
                            <ListItemButton
                                component={Link}
                                to={'/querysetv2'}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <WhatsAppIcon sx={{ color: title == 'Queryset' ? '#4287f5' : 'unset' }} />
                                </ListItemIcon>
                                <ListItemText primary='Queryset' sx={{ opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </ListItem>

                        <ListItem key='Bot Status' disablePadding sx={{ display: 'block' }}>
                            <ListItemButton
                                component={Link}
                                to={'/whatsapp'}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <SmartToyIcon sx={{ color: title == 'WhatsApp' ? '#4287f5' : 'unset' }} />
                                </ListItemIcon>
                                <ListItemText primary='Bot Status' sx={{ opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </ListItem>


                        <ListItem key='Whatsapp messages' disablePadding sx={{ display: 'block' }}>
                            <ListItemButton
                                component={Link}
                                to={'/whatsapp_messages'}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <MessageOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary='Whatsapp Messages' sx={{ opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </ListItem>

                        <Divider />

                        <ListItem key='SRM' disablePadding sx={{ display: 'block' }}>
                            <ListItemButton
                                component={Link}
                                to={'/suppliers'}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <LocalShippingIcon sx={{ color: title == 'Suppliers' ? '#4287f5' : 'unset' }} />
                                </ListItemIcon>
                                <ListItemText primary='SRM' sx={{ opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </ListItem>


                        {authState.admin ? (<>
                            <Divider />
                            <ListItem key='HRM' disablePadding sx={{ display: 'block' }}>
                                <ListItemButton
                                    component={Link}
                                    to={'/hrm'}
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Diversity3Icon sx={{ color: title == 'Suppliers' ? '#4287f5' : 'unset' }} />
                                    </ListItemIcon>
                                    <ListItemText primary='HRM' sx={{ opacity: open ? 1 : 0 }} />
                                </ListItemButton>
                            </ListItem>

                            <Divider />
                        </>

                        ) : ""}


                        {/* <ListItem key='Test' disablePadding sx={{ display: 'block' }}>
                            <ListItemButton
                                component={Link}
                                to={'/test'}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Diversity3Icon sx={{ color: title == 'Suppliers' ? '#4287f5' : 'unset' }} />
                                </ListItemIcon>
                                <ListItemText primary='Test' sx={{ opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </ListItem> */}
                    </List>
                    {/* <Divider /> */}
                </Drawer>
            )
            }
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    padding: 3,
                    marginLeft: `${marginLeft}px`, // Apply the calculated margin
                    transition: "margin-left 0.3s ease", // Smooth transition
                }}
            >
                <DrawerHeader />
                {children}
            </Box>
            <LogoutDialog />
        </Box >
    );
}
