import React, { useCallback, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../components/AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { Alert, Box, Rating, Snackbar, Typography } from '@mui/material';
import { API_DOMAIN } from '../components/constants';
import { Modal } from 'react-bootstrap';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import { BarChart, PieChart, Pie, Legend, Tooltip, CartesianGrid, XAxis, YAxis, Bar, ResponsiveContainer, Rectangle } from 'recharts';

const animatedComponents = makeAnimated();
var moment = require('moment');

function Dashboard() {
    const chartSetting = {
        yAxis: [
            {
                label: 'Total in Numbers',
            },
        ],
        width: 800,
        height: 300,
        sx: {
            [`.${axisClasses.left} .${axisClasses.label}`]: {
                transform: 'translate(-10px, 0)',
            },
        },
    };

    const { authState } = useContext(AuthContext);


    var year = new Date().getFullYear();
    const [graphDataset, setGraphDataset] = useState([{ london: 30, paris: 60, newYork: 60, seoul: 30, month: 'Jan - ' + year }, { london: 5, paris: 5, newYork: 5, seoul: 5, month: 'Feb - ' + year }, { london: 5, paris: 5, newYork: 5, seoul: 5, month: 'Mar - ' + year }, { london: 5, paris: 5, newYork: 5, seoul: 5, month: 'Apr - ' + year }, { london: 5, paris: 5, newYork: 5, seoul: 5, month: 'May - ' + year }, { london: 5, paris: 5, newYork: 5, seoul: 5, month: 'June - ' + year }, { london: 5, paris: 5, newYork: 5, seoul: 5, month: 'July - ' + year }, { london: 5, paris: 5, newYork: 5, seoul: 5, month: 'Aug - ' + year }, { london: 5, paris: 5, newYork: 5, seoul: 5, month: 'Sept - ' + year }, { london: 5, paris: 5, newYork: 5, seoul: 5, month: 'Oct - ' + year }, { london: 5, paris: 5, newYork: 5, seoul: 5, month: 'Nov - ' + year }, { london: 5, paris: 5, newYork: 5, seoul: 5, month: 'Dec - ' + year }]);

    const [supplierSet, setsupplierSet] = useState([]);
    const [customerSet, setcustomerSet] = useState([]);
    const [carBrandSet, setcarBrandSet] = useState([]);
    const [carModelSet, setcarModelSet] = useState([]);
    const [queryStatusSet, setqueryStatusSet] = useState([]);

    const [countriesSet, setCountriesSet] = useState([]);
    const [open_edit, setModalEdit] = useState(false);

    const [startDate, setStartDate] = useState(new Date(moment().format('YYYY-MM-DD HH:mm:ss')));
    const [endDate, setEndDate] = useState(new Date(moment().format('YYYY-MM-DD HH:mm:ss')));

    const onSearchDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }

    const [series, setSeries] = useState([]);
    const [queryData, setQueryData] = useState([]);
    const [xAxisSet, setXAxisSet] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [modeSelect, setModeSelect] = useState("customer");
    const [countrySelect, setCountrySelect] = useState("");
    const [supplierSelect, setSupplierSelect] = useState("");
    const [customerSelect, setCustomerSelect] = useState([]);
    const [carBrandSelect, setCarBrandSelect] = useState("");
    const [carModelSelect, setCarModelSelect] = useState("");
    const [querydataPie, setQuerydataPie] = useState([]);

    const [modelsDisabled, setModelsDisabled] = useState(true);

    const handleCloseEdit = () => {
        setModalEdit(false);
    }
    const resetInput = () => {
        setSupplierSelect("");
        setCustomerSelect([]);
        setCarBrandSelect("");
        setCarModelSelect("");
    }

    const handleChange = (selected) => {
        setCustomerSelect(selected);
    };

    const handleModeChange = (value) => {
        setModeSelect(value);
        if (!value) {
            document.querySelectorAll(".customer_mode").forEach(function (element) {
                element.style.display = "";
            });
        }
        switch (value) {
            case "customer":
                resetInput();
                document.querySelectorAll(".customer_mode").forEach(function (element) {
                    element.style.display = "";
                });
                document.querySelectorAll(".query_mode").forEach(function (element) {
                    element.style.display = "none";
                });
                break;

            case "queries":
                resetInput();
                document.querySelectorAll(".query_mode").forEach(function (element) {
                    element.style.display = "";
                });
                break;

            case "supplier":
                document.querySelectorAll(".customer_mode").forEach(function (element) {
                    element.style.display = "none";
                });

                break;

            default:
                break;
        }
    }

    //success snackbar stuff
    const [openSuccessAlert, setSuccessAlert] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const handleOpenSuccess = (reason) => {
        setSuccessMessage(reason);
        setSuccessAlert(true);
    };

    const handleCloseSuccess = () => {
        setSuccessAlert(false);
    };

    const handleGraphBtnClick = (e) => {
        switch (modeSelect) {
            case "customer":
                loadCustomerGraphData(startDate, endDate, countrySelect);
                setXAxisSet([{ scaleType: 'band', dataKey: 'month_name' }])
                break;
            case "queries":
                break;
            case "supplier":
                break;
            default:
                break;
        }
    }

    const loadCustomerCountries = () => {
        axios.post(API_DOMAIN + "/whatsapp/get_countries", { api_key: "uzdpart" }).then((res) => {
            if (res.data.success)
                setCountriesSet(res.data.data);
        });
    }

    const loadCustomerGraphData = (startDate, endDate, countrySelect) => {
        axios.post(API_DOMAIN + "/whatsapp/get_customer_graph_data", { api_key: "uzdpart", from: startDate, to: endDate, country: countrySelect }).then((res) => {
            if (res.data.success) {
                setGraphDataset(res.data.data);
                if (res.data.data.length > 0) {
                    const keyval = Object.entries(res.data.data[0])
                    let series = [];
                    keyval.forEach((element) => {
                        if (element[0] != "month_name" && element[0] != "total_count") {
                            series.push({ dataKey: element[0], label: element[0], showLabel: false });
                        }
                    });
                    setSeries(series);
                }
            }
        });

    }

    const loadSupplierData = () => {
        axios.post(API_DOMAIN + "/whatsapp/get_suppliers", { api_key: "uzdpart" }).then((res) => {
            if (res.data.success) {
                setsupplierSet(res.data.data);
            }
        });
    }

    const loadCustomerData = () => {
        axios.post(API_DOMAIN + "/whatsapp/get_customers", { api_key: "uzdpart" }).then((res) => {
            if (res.data.success) {
                const customerSetTemp = res.data.data.map(item => ({
                    value: item.customer_mobile,
                    label: item.customer_name
                }));
                setcustomerSet(customerSetTemp);
            }
        });
    }

    const loadQueryStatusData = () => {
        axios.post(API_DOMAIN + "/whatsapp/get_query_succ_status", { api_key: "uzdpart" }).then((res) => {
            if (res.data.success) {
                console.log("query status data == " + JSON.stringify(res.data.data));
                setqueryStatusSet(res.data.data);
            }
        });
    }

    const handleCarBrandSelect = (value) => {
        if (value != null && value != "" && value != undefined) {
            setCarBrandSelect(value);
            document.getElementById("models").disabled = true;
            //the models field will be enabled after the brand is selected and the models are loaded
            loadCarModelData(value);
        }
        else {
            document.getElementById("models").disabled = true;
            document.getElementById("models").value = "";
            setCarBrandSelect("");
            setCarModelSelect("");
        }
    }

    const loadCarBrandData = () => {
        axios.post(API_DOMAIN + "/whatsapp/get_makes_v2", { api_key: "uzdpart" }).then((res) => {
            if (res.data.success) {
                setcarBrandSet(res.data.data);
            }
        });
    }

    const loadCarModelData = (make) => {
        axios.post(API_DOMAIN + "/whatsapp/get_models_v2", { api_key: "uzdpart", make: make }).then((res) => {
            if (res.data.success) {
                setcarModelSet(res.data.data);
                setModelsDisabled(false);
                // document.getElementById("models").disabled = false;
            }
        });

    }

    const loadQueryData = () => {
        axios.post(API_DOMAIN + "/whatsapp/get_dashboard_query_data", { user: authState.id }).then((res) => {
            if (res.data.success) {
                console.log("asasasas == " + JSON.stringify(res.data.data));
                setQueryData(res.data.data);

                const formattedData = res.data.data.total_queries_status.map((item, index) => ({
                    id: index,
                    value: item.count,
                    name: item.status,
                    label: item.status,
                }));

                for (let i = 0; i < formattedData.length; i++) {
                    if (formattedData[i].name == "Disabled") {
                        formattedData.splice(i, 1);
                    }

                }
                console.log("formattedData == " + JSON.stringify(formattedData));
                setQuerydataPie(formattedData);
            }
        });
    }

    useEffect(() => {

        loadCustomerCountries();
        loadSupplierData();
        loadCustomerData();
        loadCarBrandData();
        loadCarModelData();
        loadQueryStatusData();
        loadQueryData();
    }, []);

    return (
        <div>
            <div style={{ border: "solid 2px black", borderRadius: "5px", padding: "5px", width: "76vw", display: "flex", justifyContent: "space-between", alignItems: "center" }}>




                <Card sx={{ width: 550, height: "100%" }}>
                    <CardActionArea>
                        <CardContent>
                            <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                                sx={{ textAlign: "center" }}
                            >
                                Today Queries Info ({new Date().toISOString().substring(0, 10)})
                            </Typography>

                            {/* <PieChart
                                series={[
                                    {
                                        data: querydataPie,
                                    },
                                ]}
                                width={400}
                                height={200}
                            /> */}


                            <PieChart width={500} height={200}>
                                <Pie
                                    data={querydataPie}
                                    dataKey="value"
                                    nameKey="name"
                                    cx="50%"
                                    cy="50%"
                                    outerRadius={80}
                                    fill="#8884d8"
                                />
                                <Tooltip />
                                <Legend layout="vertical" align="right" verticalAlign="middle" />
                            </PieChart>


                            <BarChart
                                width={500}
                                height={300}
                                data={querydataPie}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Bar
                                    dataKey="value"
                                    fill="#B3CDAD"
                                    activeBar={<Rectangle fill="yellow" stroke="blue" />}
                                />
                            </BarChart>
                        </CardContent>
                    </CardActionArea>
                </Card>



                <div>

                    <Box sx={{ padding: "10px", backgroundColor: "green", borderRadius: "5px", color: "white" }}>
                        <h5>Target for Time period {queryData && Array.isArray(queryData.user_data) && queryData.user_data.length > 0 && queryData?.user_data[0]?.moredetails?.targetRange?.toString().replaceAll('T', " ").replace("Z", "~").replace("Z", "").substring(0, 10) + '~' + queryData?.user_data[0]?.moredetails?.targetRange?.toString().replaceAll('T', " ").replace("Z", "~").replace("Z", "").substring(25, 35)}</h5>
                        <div>{queryData && Array.isArray(queryData.user_data) && queryData.user_data.length > 0 && queryData?.user_data[0]?.moredetails?.targetAmount}</div>
                    </Box><div style={{ height: "10px" }}></div><Box sx={{ padding: "10px", backgroundColor: "green", borderRadius: "5px", color: "white" }}>
                        <h5>Total Sales Today </h5>
                        <div>{queryData && queryData.sale_today ? queryData.sale_today : 0}</div>
                    </Box><div style={{ height: "10px" }}></div><Box sx={{ padding: "10px", backgroundColor: "green", borderRadius: "5px", color: "white" }}>
                        <h5>Total Sales During Time period </h5>
                        <div>{queryData && queryData.total_sale ? queryData.total_sale : 0}</div>
                    </Box><div style={{ height: "10px" }}></div><Box sx={{ padding: "10px", backgroundColor: "green", borderRadius: "5px", color: "white" }}>
                        <h5>Balance to Target </h5>
                        <div>{queryData && queryData.total_sale ? queryData?.user_data[0]?.moredetails?.targetAmount - queryData.total_sale : 0}</div>
                    </Box>


                </div>
            </div>
            <div style={{ height: "20px" }}></div>
            <div style={{ display: "flex" }} className='div1'>
                <div style={{ border: "solid 2px black", borderRadius: "5px", padding: "5px" }} className='div2'>
                    <div style={{ textAlign: "center" }}>SELECT PARAMETERS</div>
                    <div>&nbsp;</div>
                    <div style={{ width: "350px" }}>
                        <table>
                            <tr>
                                <td>Mode</td>
                                <td>:</td>
                                <td><select id="modes" style={{ width: "100%" }} onChange={(e) => handleModeChange(e.target.value)} defaultValue={"customer"}>
                                    <option value="customer">Customer</option>
                                    <option value="queries">Queries</option>
                                    {/* <option value="supplier">Supplier</option> */}
                                </select></td>
                            </tr>
                            {/* ////////////////////Customer mode////////////////////// */}
                            <tr class="customer_mode">
                                <td>Select Country</td>
                                <td>:</td>
                                <td><select id="countries" style={{ width: "100%" }} onChange={(e) => setCountrySelect(e.target.value)}>
                                    <option value=""></option>
                                    {countriesSet.map((country) => (
                                        <option value={country}>{country}</option>
                                    ))}
                                </select></td>
                            </tr>
                            <tr class="common_mode">
                                <td>Select Date Range</td>
                                <td>:</td>
                                <td style={{ width: "150px" }}>
                                    <DatePicker
                                        selected={startDate}
                                        onChange={onSearchDateChange}
                                        startDate={startDate}
                                        endDate={endDate}
                                        placeholderText="Date Range From - To"
                                        className='react-datepicker1'
                                        dateFormat="dd/MM/yyyy"
                                        selectsRange
                                    />
                                </td>
                            </tr>
                            {/* ////////////////////Query mode////////////////////// */}
                            <tr class="query_mode" style={{ display: "none" }}>
                                <td>Supplier</td>
                                <td>:</td>
                                <td><select id="suppliers" style={{ width: "100%" }} value={supplierSelect} onChange={(e) => setSupplierSelect(e.target.value)}>
                                    <option value=""></option>
                                    {supplierSet.map((supplier) => (
                                        <option value={supplier.supplier_id}>{supplier.supplier_name}</option>
                                    ))}
                                </select></td>
                            </tr>
                            <tr class="query_mode" style={{ display: "none" }}>
                                <td>Customer</td>
                                <td>:</td>
                                <td>
                                    {/* <ReactTags
                                    sx={{ width: "220px !important", }}
                                    selected={customerSelect}
                                    suggestions={customerSet}
                                    placeholderText="Select multiple customers"
                                    onAdd={onAdd}
                                    onDelete={onDelete}
                                    noOptionsText="No matches found"
                                /> */}
                                    <Select
                                        styles={{ fontSize: "50px" }}
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        isMulti
                                        onChange={handleChange}
                                        options={customerSet}
                                        value={customerSelect}
                                    />
                                </td>
                            </tr>
                            <tr class="query_mode" style={{ display: "none" }}>
                                <td>Car Brand</td>
                                <td>:</td>
                                <td><select id="brands" style={{ width: "100%" }} value={carBrandSelect} onChange={(e) => handleCarBrandSelect(e.target.value)}>
                                    <option value=""></option>
                                    {carBrandSet.map((brand) => (
                                        <option value={brand.name}>{brand.name}</option>
                                    ))}
                                </select></td>
                            </tr>
                            <tr class="query_mode" style={{ display: "none" }}>
                                <td>Car Model</td>
                                <td>:</td>
                                <td><select id="models" style={{ width: "100%" }} value={carModelSelect} onChange={(e) => setCarModelSelect(e.target.value)} disabled={modelsDisabled}>
                                    <option value=""></option>
                                    {carModelSet.map((model) => (
                                        <option value={model.model}>{model.model}</option>
                                    ))}
                                </select></td>
                            </tr>

                            <tr>
                                <td></td>
                                <td colSpan={2} style={{ justifyContent: "center" }}>
                                    <button id='graph_button' style={{
                                        width: "140px",
                                        height: "30px",
                                        backgroundColor: "#5380fc",
                                        border: "none",  /* Ensure no default button border */
                                        boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",  /* Shadow effect */
                                        transition: "box-shadow 0.1s ease",  /* Smooth transition */
                                    }}
                                        onMouseOver={(event) => {
                                            // Add hover effect
                                            event.target.style.boxShadow = "4px 4px 8px rgba(0, 0, 0, 0.5)";
                                        }}
                                        onMouseOut={(event) => {
                                            // Remove hover effect
                                            event.target.style.boxShadow = "2px 2px 4px rgba(0, 0, 0, 0.3)";
                                        }}
                                        onClick={handleGraphBtnClick}>Generate Graph</button>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div>&nbsp;</div>
                <div className="outer-container">
                    <div>
                        <div style={{ textAlign: "center" }}>RESULT IN GRAPH VIEW</div>
                        <BarChart
                            dataset={graphDataset}
                            xAxis={xAxisSet}
                            series={series}
                            {...chartSetting}
                        />
                    </div>
                    <Modal
                        open={open_edit}
                        onClose={handleCloseEdit}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        BackdropProps={{ onClick: (event) => event.stopPropagation() }}
                        disableEscapeKeyDown >
                        <Box>

                        </Box>
                    </Modal>
                    {/* General purpose success alert */}
                    <Snackbar open={openSuccessAlert} autoHideDuration={6000} onClose={handleCloseSuccess}>
                        <Alert onClose={handleCloseSuccess} severity="success" sx={{ width: '100%' }}>
                            {successMessage}
                        </Alert>
                    </Snackbar>
                </div >
            </div>
        </div >
    );
}

export default Dashboard