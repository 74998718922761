import React from 'react';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { Tooltip } from '@mui/material';
const moment = require('moment');

const CustomToolbarSelect = ({ selectedRows, data, onRowsDelete, setSelectedRows, onEdit, setEditWhatsapp, setQueryModalTitle, toasts }) => {



    const handleDelete = () => {
        const rowsToDelete = selectedRows.data.map(row => data[row.index]);
        onRowsDelete(rowsToDelete, setSelectedRows);
    };

    const handleEdit = () => {
        if (selectedRows.data.length > 1) {
            alert('Please select only one row to edit');
            return false;
        }

        if (data[selectedRows.data[0].index]) {
            setQueryModalTitle(`Duplicating the query (${data[selectedRows.data[0].index].query_id}) to create a new one`);
            setTimeout(() => {
                onEdit(data[selectedRows.data[0].index], "duplicate");
            }, 0);
        }
    };

    const handleEditWhatsappFun = () => {
        const now = moment();
        if (selectedRows.data.length > 1) {
            alert('Please select only one row to edit');
            return false;
        }
        if (data[selectedRows.data[0].index]) {
            const queryCreated = moment(data[selectedRows?.data[0]?.index]?.timeline[0]?.query_created, "YYYY-MM-DD hh:mm:ss A");
            const diff = now.diff(queryCreated, 'minutes');

            if (diff > 15) {
                setQueryModalTitle(`Modifying the query (${data[selectedRows.data[0].index].query_id}) and sending it as a replacement to the WhatsApp`);
                toasts.WarningSnackbar('You can only edit the query within 15 minutes of creation');
                toasts.InfoSnackbar('Instead this query will be duplicated to create a new one replacing the old one!');
                setTimeout(() => {
                    setEditWhatsapp(true);
                    onEdit(data[selectedRows.data[0].index], "same");
                }, 0);

            }
            else {
                setQueryModalTitle(`Editing the query (${data[selectedRows?.data[0]?.index]?.query_id}) to edit and update the same in the WhatsApp as well`);
                setTimeout(() => {
                    setEditWhatsapp(true);
                    onEdit(data[selectedRows.data[0].index], "same");
                }, 0);
            }
        }
    }

    return (
        <div>
            <Tooltip title="Edit and update the same query messages already sent through WhatsApp.">
                <IconButton onClick={handleEditWhatsappFun}>
                    <EditNoteIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Use this selected query data to generate a new query.">
                <IconButton onClick={handleEdit}>
                    <EditIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Delete the query.">
                <IconButton onClick={handleDelete}>
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
        </div>
    );
};

export default CustomToolbarSelect;
